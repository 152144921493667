import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";

import axios from 'axios';

// import Grid from '../layouts/gridnosidebarComponent';

import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Modal from 'react-responsive-modal';
import EditItem from "./editaction";
import { Timeline, TimelineItem }  from 'vertical-timeline-component-for-react';
 
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");


export default class ManageSNDConsignments extends Component {

	constructor(props){
		super(props);
		this.state={ 
			modules: AllModules,
			pageTitle: "Customers",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			rowData:[],
			error:"",
			error1:"",
			errorr:"",
			errorr1:"",
			distinct_codes : [],
			distinct_names : [],
			uploadDivWidth:'0%',
			sliderTranslate:'',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			usermanualmodal:false,
			sliderAddTransporter:"",
			transporter_name:"",
			transporter_code:"",
			rowid:"",
			utransporter_name:"",
			utransporter_code:"",
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
			
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
		 
			frameworkComponents: {
				EditItem:EditItem,
			},

			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			sliderEditTransporter:"",
			
			consignee_name:"",
			consignee_code:"",
			lat:"",
			lng:"",
			for_code:"",
			consignee_city: "",
			consignee_state: "",
			consignee_address:"",
			consignee_pincode:"",
			consignee_addr1: "",
			consignee_addr2: "",
			consignee_addr3: "",
			uconsignee_name:"",
			uconsignee_code:"",
			old_consignee_code:"",
			old_consignee_name:"",
			ulat:"",
			ulng:"",
			ufor_code:"",
			uconsignee_city: "",
			uconsignee_state:"",
			uconsignee_address:"",
			uconsignee_pincode:"",
			uconsignee_addr1: "",
			uconsignee_addr2: "",
			uconsignee_addr3: "",
			
		}
		this.OnLoadCustomers = this.OnLoadCustomers.bind(this);
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}

	componentDidMount(){
		 /*Consignments List*/	
		 this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

	  this.OnLoadCustomers();
	};
	OnLoadCustomers(){
		var plant_code ="";
		if(localStorage.getItem("pc") != undefined && localStorage.getItem("pc") != "undefined" && localStorage.getItem("pc") != "" &&localStorage.getItem("pc") != null){
			plant_code = localStorage.getItem("pc")
		}
		var params ={
			plant_code: plant_code
		}
		redirectURL.post('/consignees/customers',params)    
		.then((response) => {
		  var records = JSON.parse(JSON.stringify(response)).data;
		  var distinctConsigneeCodes = [...new Set(records.map(item => item.consignee_code))];
		  var distinctConsigneeNames = [...new Set(records.map(item => item.consignee_name))];
		  console.log("records", records);
		  this.setState({
			  rowData:records,
			  distinct_codes : distinctConsigneeCodes,
			  distinct_names : distinctConsigneeNames,
			  loadshow:"show-n",
			  overly:"show-n"
		  });
		})
		.catch(function (error) {
		  console.log(error);
		  });
	}
	onShowUploadDiv = () => {
		console.log("Click Upload Button")
		this.setState({
			uploadDivWidth:'30%',
			sliderTranslate:"slider-translate",
			showDiv:'show-m',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderAddTransporter:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			sliderEditTransporter:""
		});
	}
	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			consignee_code: "",
			consignee_name: "",
			consignee_city: "",
			consignee_state: "",
			consignee_address: "",
			consignee_pincode: "",
			lat: "",
			lng: "",
			error: "",
			errorr: "",
			sliderAddTransporter:'',
			sliderEditTransporter:""
		});
		
    }
	onCLickAddCustomer(){
		this.setState({
			sliderAddTransporter:'slider-translate',
			overly:"show-m"
		});
	}
	changeHandler(event) {
		var name = event.target.name;
		var value = event.target.value;

		var normalizedValue = value.trim().replace(/\s+/g, '').toLowerCase(); // trim and replace multiple spaces with single space
		var normalizedOldCode = this.state.old_consignee_code.trim().replace(/\s+/g, '').toLowerCase();
		var normalizedOldName = this.state.old_consignee_name.trim().replace(/\s+/g, '').toLowerCase();

		if (name === "consignee_code") {
			if (this.state.distinct_codes.some(code => code.trim().replace(/\s+/g, '').toLowerCase() === normalizedValue)) {
				this.setState({ error: "Customer code already exists" });
			} else {
				this.setState({ error: "" });
			}
		}
		if (name === "consignee_name") {
			if (this.state.distinct_names.some(name => name.trim().replace(/\s+/g, '').toLowerCase() === normalizedValue)) {
				this.setState({ error1: "Customer name already exists" });
			} else {
				this.setState({ error1: "" });
			}
		}
		if (name == "uconsignee_code" && normalizedValue !== normalizedOldCode) {
			if (this.state.distinct_codes.some(code => code.trim().replace(/\s+/g, '').toLowerCase() === normalizedValue)) {
				this.setState({ errorr: "Customer code already exists" });
			} else {
				this.setState({ errorr: "" });
			}
		}
		if (name == "uconsignee_name" && normalizedValue !== normalizedOldName) {
			if (this.state.distinct_names.some(name => name.trim().replace(/\s+/g, '').toLowerCase() === normalizedValue)) {
				this.setState({ errorr1: "Customer name already exists" });
			} else {
				this.setState({ errorr1: "" });
			}
		}
		this.setState({ [name]: value });
	}
	onClickEditForm(rowdata){
		var row = rowdata.data;
		// console.log("Row ", row)
		var lat = "";
		var lng = "";
		if(row.coordinates !=undefined && row.coordinates !="")
		{
			var cordinates = JSON.parse(row.coordinates);
			lat = cordinates.lat;
			lng = cordinates.lng;
		}
		// console.log('cordinates ', cordinates);
		this.setState({
			uconsignee_name:row.consignee_name,
			uconsignee_code:row.consignee_code,
			old_consignee_code:row.consignee_code,
			old_consignee_name : row.consignee_name,
			ulat:lat,
			ulng:lng,
			ufor_code:row.for_code,
			uconsignee_city: row.consignee_city,
			uconsignee_state: row.consignee_state,
			uconsignee_address:row.consignee_address,
			uconsignee_pincode:row.pin_code,
			uconsignee_addr1: row.consignee_addr1,
			uconsignee_addr2: row.consignee_addr2,
			uconsignee_addr3: row.consignee_addr3,
			rowid:row._id,
			sliderEditTransporter:'slider-translate',
			overly:"show-m"
		})
	}
	saveCustomerItem(event){
		event.preventDefault();
		var consignee_code = this.state.consignee_code;
		var consignee_name = this.state.consignee_name;
		var consignee_city = this.state.consignee_city;
		var consignee_state = this.state.consignee_state;
		var consignee_address = this.state.consignee_address;
		var consignee_pincode = this.state.consignee_pincode;
		var lat = this.state.lat;
		var lng = this.state.lng;
		var consignee_addr1 = this.state.consignee_addr1;
		var consignee_addr2 = this.state.consignee_addr2;
		var consignee_addr3 = this.state.consignee_addr3;
		// var for_code = this.state.for_code;
		var params = {
			consignee_code:consignee_code,
			consignee_name:consignee_name,
			consignee_city:consignee_city,
			consignee_state:consignee_state,
			consignee_address:consignee_address,
			consignee_pincode:consignee_pincode,
			lat:lat,
			lng:lng,
			consignee_addr1:consignee_addr1,
			consignee_addr2:consignee_addr2,
			consignee_addr3:consignee_addr3,
			// for_code:for_code
		}
		console.log(params,"customerparams")
		if(this.state.error == "" && this.state.error1 == ""){
			if(consignee_code != "" && consignee_name != "" && lat != "" && lng != "")
			{
				redirectURL.post("/consignees/addcustomer",params)
				.then((response) => {
					if(response.data.status == "success")
					{
						this.setState({
							show:true,
							basicTitle:"Successfully added customer",
							basicType:"success",
							consignee_code:"",
							consignee_name:"",
							consignee_city:"",
							consignee_state:"",
							consignee_address:"",
							consignee_pincode:"",
							lat:"",
							lng:"",
							consignee_addr1:"",
							consignee_addr2:"",
							consignee_addr3:"",
							for_code:"",
							sliderAddTransporter:"",
							overly:"show-n"
						})
						this.OnLoadCustomers();
					}
					else{
						this.setState({
							show:true,
							basicTitle:"Failed to add customer",
							basicType:"danger"
						})
					}
					
				})
			}
		}
		else {
			this.setState({
				show:true,
				basicTitle:"Please fix the errors before submitting the form",
				basicType:"danger"
			})
		}
		
	}
	updateCustomerItem(event){
		event.preventDefault();
		var consignee_code = this.state.uconsignee_code;
		var old_consignee_code = this.state.old_consignee_code;
		var consignee_name = this.state.uconsignee_name;
		var consignee_city = this.state.uconsignee_city;
		var consignee_state = this.state.uconsignee_state;
		var consignee_address = this.state.uconsignee_address;
		var consignee_pincode = this.state.uconsignee_pincode;
		var lat = this.state.ulat;
		var lng = this.state.ulng;
		var consignee_addr1 = this.state.uconsignee_addr1;
		var consignee_addr2 = this.state.uconsignee_addr2;
		var consignee_addr3 = this.state.uconsignee_addr3;
		// var for_code = this.state.for_code;
		var params = {
			consignee_code:consignee_code,
			old_consignee_code:old_consignee_code,
			consignee_name:consignee_name,
			consignee_city:consignee_city,
			consignee_state:consignee_state,
			consignee_address:consignee_address,
			consignee_pincode:consignee_pincode,
			lat:lat,
			lng:lng,
			consignee_addr1:consignee_addr1,
			consignee_addr2:consignee_addr2,
			consignee_addr3:consignee_addr3,
			rowid:this.state.rowid
			// for_code:for_code
		}
		if(this.state.errorr == "" && this.state.errorr1 == ""){
			if(consignee_code != "" && consignee_name != "" && lat != "" && lng != "")
			{
				redirectURL.post("/consignees/updatecustomer",params)
				.then((response) => {
					if(response.data.status == "success")
					{
						this.setState({
							show:true,
							basicTitle:"Successfully added customer",
							basicType:"success",
							uconsignee_code:"",
							old_consignee_code:"",
							uconsignee_name:"",
							uconsignee_city:"",
							uconsignee_state:"",
							uconsignee_address:"",
							uconsignee_pincode:"",
							ulat:"",
							ulng:"",
							uconsignee_addr1:"",
							uconsignee_addr2:"",
							uconsignee_addr3:"",
							// ufor_code:"",
							sliderEditTransporter:"",
							overly:"show-n"
						})
						this.OnLoadCustomers();
					}
					else{
						this.setState({
							show:true,
							basicTitle:"Failed to add customer",
							basicType:"danger"
						})
					}
					
				})
			}}
			else {
				this.setState({
					show:true,
					basicTitle:"Please fix the errors before submitting the form",
					basicType:"danger"
				})
			}
	}
	closeAlert = () => {
        this.setState({
            show: false
        });
    }
    render(){
		const modalStyles  = {
			width:'1300px !important',
		}
		
		const {usermanualmodal} = this.state;
		const columnwithDefs = [
	  	        {
	  	          headerName: "Customer Name",
	  	          field: "consignee_name",
	  	          width: 200,
	    	          editable:false,
					  resizable:true,
	    	          filter: "agSetColumnFilter"
		        },
		        {
	  	          headerName: "Customer Code",
	  	          field: "consignee_code",
					resizable:true,
	  	          width: 150,
	    	          editable:false,
	    	          filter: "agSetColumnFilter"
				},
				{
	  	          	headerName: "Latitude",
	  	          	field: "coordinates",
						resizable:true,
	  	          	width: 150,
					editable:false,
					valueGetter:function(params){
						try{
							var corrds = params.data.coordinates;
							// var cordinates = corrds.replace(/'/g, '"')
							var cordinates = JSON.parse(corrds);
							return cordinates.lat;
						}
						catch(e){
							return {"lat":0, "lng":0};
							
						}
						
					},
					filter: "agSetColumnFilter"
		        },
				{
	  	          	headerName: "Longitude",
	  	          	field: "coordinates",
						resizable:true,
	  	          	width: 150,
					editable:false,
					valueGetter:function(params){
						try{
							var corrds = params.data.coordinates;
							// var cordinates = corrds.replace(/'/g, '"')
							var cordinates = JSON.parse(corrds);
							return cordinates.lng;
						}
						catch(e){
							return {"lat":0, "lng":0};
						}
						
					},
					filter: "agSetColumnFilter"
		        },
				// {
	  	        //   	headerName: "For Code",
	  	        //   	field: "for_code",
	  	        //   	width: 150,
				// 	editable:false,
				// 	filter: "agSetColumnFilter"
		        // },
				{
	  	          	headerName: "Customer City",
	  	          	field: "consignee_city",
						resizable:true,
	  	          	width: 200,
					editable:false,
					filter: "agSetColumnFilter"
		        },
				{
	  	          	headerName: "Customer State",
	  	          	field: "consignee_state",
						resizable:true,
	  	          	width: 200,
					editable:false,
					filter: "agSetColumnFilter"
		        },
			{
				headerName: "Customer Address",
				field: "consignee_address",
				resizable: true,
				width: 200,
				editable: false,
				filter: "agSetColumnFilter"
			},
			{
				headerName: "Customer Pincode",
				field: "pin_code",
				resizable: true,
				width: 200,
				editable: false,
				filter: "agSetColumnFilter"
			},
				// {
	  	        //   	headerName: "Address 1",
	  	        //   	field: "consignee_addr1",
	  	        //   	width: 200,
				// 	editable:false,
				// 	filter: "agSetColumnFilter"
		        // },
				// {
	  	        //   	headerName: "Address 2",
	  	        //   	field: "consignee_addr2",
	  	        //   	width: 200,
				// 	editable:false,
				// 	filter: "agSetColumnFilter"
		        // },
				// {
	  	        //   	headerName: "Address 3",
	  	        //   	field: "consignee_addr3",
	  	        //   	width: 200,
				// 	editable:false,
				// 	filter: "agSetColumnFilter"
		        // },
		        {
		          headerName: "Action",
		          field: "_id",
				  resizable:true,
		          width: 100,
				  filter: false,
		          cellRenderer:'EditItem',
		          editable:false
		        }
	  	        
	    ];
		return(
			<div className="container-fluid">
			<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        > 
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pageTitle} </span>
									{/*
									<button type="button" onClick={this.onShowUploadDiv.bind(this)} className="btn btn-success pull-right cs-btn"> <i className="icofont icofont-upload-alt"></i> Testing</button>
				       				*/}
								
									<button type="button" onClick={this.onCLickAddCustomer.bind(this)} className="btn btn-warning pull-right">Add Customer</button>
                                
								</h5>
				   			</div>				   			
		            		<div className="card-body">
								<div id="myGrid" style={{ height: "450px",width: "100%"}}  className="ag-theme-balham">
									<AgGridReact
										modules={this.state.modules}
										columnDefs={columnwithDefs}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.rowData}
										// rowSelection={this.state.rowSelection}
										enableCharts={false}
										//  paginationAutoPageSize={true}
										paginationPageSize={this.state.paginationPageSize}
										pagination={true}
										// enableRangeSelection={true}
										onGridReady={this.onGridReady}
										onGridState={this.onGridState}
										deleteRowHandler={this.deleteRowHandler}
										// onSelectionChanged={this.onSelectionChanged.bind(this)}
										frameworkComponents={this.state.frameworkComponents}
										//editType={this.state.editType}
										stopEditingWhenGridLosesFocus={true}
										floatingFilter={true}
										enableCellChangeFlash={true}
										suppressCellFlash={true}
										gridOptions={{
											context: { componentParent: this }
										}}
										//updateRowHandler={this.updateRowHandler}
										//onCellEditingStopped={this.updateCellData.bind(this)}
									/>
								</div>
				   			</div>
				   		</div>	
				   	</div>
				 </div>

				 <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

				<div className={"slide-r "+(this.state.sliderAddTransporter)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Add Customer
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.saveCustomerItem.bind(this)}>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Cusomter Code</label>
							    <div className='col-xl-12 col-lg-12'><input type="text" placeholder="Enter Customer Code" onChange={this.changeHandler.bind(this)} value={this.state.consignee_code} id="consignee_code" name="consignee_code" className="form-control" required /></div>
								<p className='col-xl-12 col-lg-12' style={{color: "red"}}>{this.state.error}</p>
							</div>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12"> Customer Name</label>
							    <div className='col-xl-12 col-lg-12'><input type="text" placeholder="Enter Customer Name" onChange={this.changeHandler.bind(this)} value={this.state.consignee_name} id="consignee_name" name="consignee_name" className="form-control" required /></div>
								<p className='col-xl-12 col-lg-12' style={{color: "red"}}>{this.state.error1}</p>
							</div>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12"> Customer City</label>
							    <div className='col-xl-12 col-lg-12'><input type="text" placeholder="Enter City" onChange={this.changeHandler.bind(this)} value={this.state.consignee_city} id="consignee_city" name="consignee_city" className="form-control" required /></div>
							</div>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12"> Customer State</label>
							    <div className='col-xl-12 col-lg-12'><input type="text" placeholder="Enter State" onChange={this.changeHandler.bind(this)} value={this.state.consignee_state} id="consignee_state" name="consignee_state" className="form-control" required /></div>
							</div>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12"> Customer Address</label>
							    <div className='col-xl-12 col-lg-12'><input type="text" placeholder="Enter Address" onChange={this.changeHandler.bind(this)} value={this.state.consignee_address} id="consignee_address" name="consignee_address" className="form-control" required /></div>
							</div>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12"> Customer Pincode</label>
							    <div className='col-xl-12 col-lg-12'><input type="text" placeholder="Enter Pincode" onChange={this.changeHandler.bind(this)} value={this.state.consignee_pincode} id="consignee_pincode" name="consignee_pincode" className="form-control" required /></div>
							</div>
							
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12"> Latitude</label>
							    <div className='col-xl-12 col-lg-12'><input type="text" placeholder="Enter Latitude" onChange={this.changeHandler.bind(this)} value={this.state.lat} id="lat" name="lat" className="form-control" required /></div>
							</div>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12"> Longitude</label>
							    <div className='col-xl-12 col-lg-12'><input type="text" placeholder="Enter Longitude" onChange={this.changeHandler.bind(this)} value={this.state.lng} id="lng" name="lng" className="form-control" required /></div>
							</div>
							{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12"> Address1</label>
							    <input type="text" placeholder="Enter Address Line1" onChange={this.changeHandler.bind(this)} value={this.state.consignee_addr1} id="consignee_addr1" name="consignee_addr1" className="form-control" required />
							</div>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12"> Address2</label>
							    <input type="text" placeholder="Enter Address Line2" onChange={this.changeHandler.bind(this)} value={this.state.consignee_addr2} id="consignee_addr2" name="consignee_addr2" className="form-control"  />
							</div>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12"> Address3</label>
							    <input type="text" placeholder="Enter Address Line3" onChange={this.changeHandler.bind(this)} value={this.state.consignee_addr3} id="consignee_addr3" name="consignee_addr3" className="form-control"  />
							</div> */}
							{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12"> For Code</label>
							    <input type="text" placeholder="Enter For Code" onChange={this.changeHandler.bind(this)} value={this.state.for_code} id="for_code" name="for_code" className="form-control"  />
							</div> */}
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

                        </div>
					</div>
				</div>

				<div className={"slide-r "+(this.state.sliderEditTransporter)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Edit Cusomter
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.updateCustomerItem.bind(this)}>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Cusomter Code</label>
							    <div className='col-xl-12 col-lg-12'><input type="text" placeholder="Enter Customer Code" onChange={this.changeHandler.bind(this)} value={this.state.uconsignee_code} id="uconsignee_code" name="uconsignee_code" className="form-control" required /></div>
								<p className='col-xl-12 col-lg-12' style={{color: "red"}}>{this.state.errorr}</p>
							</div>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12"> Customer Name</label>
							    <div className='col-xl-12 col-lg-12'><input type="text" placeholder="Enter Customer Name" onChange={this.changeHandler.bind(this)} value={this.state.uconsignee_name} id="uconsignee_name" name="uconsignee_name" className="form-control" required /></div>
								<p className='col-xl-12 col-lg-12' style={{color: "red"}}>{this.state.errorr1}</p>
							</div>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12"> Customer City</label>
							    <div className='col-xl-12 col-lg-12'><input type="text" placeholder="Enter City" onChange={this.changeHandler.bind(this)} value={this.state.uconsignee_city} id="uconsignee_city" name="uconsignee_city" className="form-control" required /></div>
							</div>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12"> Customer State</label>
							    <div className='col-xl-12 col-lg-12'><input type="text" placeholder="Enter State" onChange={this.changeHandler.bind(this)} value={this.state.uconsignee_state} id="uconsignee_state" name="uconsignee_state" className="form-control" required /></div>
							</div>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12"> Customer Address</label>
							    <div className='col-xl-12 col-lg-12'><input type="text" placeholder="Enter Address" onChange={this.changeHandler.bind(this)} value={this.state.uconsignee_address} id="uconsignee_address" name="uconsignee_address" className="form-control" required /></div>
							</div>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12"> Customer Pincode</label>
							    <div className='col-xl-12 col-lg-12'><input type="text" placeholder="Enter Pincode" onChange={this.changeHandler.bind(this)} value={this.state.uconsignee_pincode} id="uconsignee_pincode" name="uconsignee_pincode" className="form-control" required /></div>
							</div>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12"> Latitude</label>
							    <div className='col-xl-12 col-lg-12'><input type="text" placeholder="Enter Latitude" onChange={this.changeHandler.bind(this)} value={this.state.ulat} id="ulat" name="ulat" className="form-control" required /></div>
							</div>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12"> Longitude</label>
							    <div className='col-xl-12 col-lg-12'><input type="text" placeholder="Enter Longitude" onChange={this.changeHandler.bind(this)} value={this.state.ulng} id="ulng" name="ulng" className="form-control" required /></div>
							</div>
							{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12"> Address1</label>
							    <input type="text" placeholder="Enter Address Line1" onChange={this.changeHandler.bind(this)} value={this.state.uconsignee_addr1} id="uconsignee_addr1" name="uconsignee_addr1" className="form-control" required />
							</div>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12"> Address2</label>
							    <input type="text" placeholder="Enter Address Line2" onChange={this.changeHandler.bind(this)} value={this.state.uconsignee_addr2} id="uconsignee_addr2" name="uconsignee_addr2" className="form-control"  />
							</div>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12"> Address3</label>
							    <input type="text" placeholder="Enter Address Line3" onChange={this.changeHandler.bind(this)} value={this.state.uconsignee_addr3} id="uconsignee_addr3" name="uconsignee_addr3" className="form-control"  />
							</div> */}
							{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12"> For Code</label>
							    <input type="text" placeholder="Enter For Code" onChange={this.changeHandler.bind(this)} value={this.state.ufor_code} id="ufor_code" name="ufor_code" className="form-control"  />
							</div> */}
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

                        </div>
					</div>
				</div>


				 <div className={"slide-r "+(this.state.sliderTranslate)} style={{overflow:"auto"}}>
				 	
					 <div className="slide-r-title">
				 		<h4>
                            Sidebars
                        </h4>
				 	</div>
				 	<div className="slide-r-body" style={{position:"relative"}}>
						
				 		<div className="col-xl-12 col-lg-12">
				 		<div className={"col-xl-12 col-lg-12 alert alert-light uploadcscmsg "+(this.state.alerterrshow)+" "+(this.state.alerterrmg)}>{this.state.csverrmessage}</div>
						 <Timeline lineColor={'#ddd'}>
							<TimelineItem
								key="001"
								dateText="11/2010 – Present"
								dateInnerStyle={{ background: '#76bb7f', color: '#fff' }}
								bodyContainerStyle={{
								background: '#ddd',
								padding: '20px',
								borderRadius: '8px',
								boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
								}}
							>
								<p>
								123Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
								exercitation.</p>
							</TimelineItem>
							<TimelineItem
								key="002"
								dateText="04/2009 – 11/2010"
								dateInnerStyle={{ background: '#76bb7f', color: '#fff' }}
								bodyContainerStyle={{
								background: '#ddd',
								padding: '20px',
								borderRadius: '8px',
								boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
								}}
							>
								<p>
								456Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
								exercitation.</p>
							</TimelineItem>
							<TimelineItem
								key="003"
								dateComponent={(
								<div
									style={{
									display: 'block',
									float: 'left',
									padding: '10px',
									background: 'rgb(150, 150, 150)',
									color: '#fff',
									}}
								>
									11/2008 – 04/2009
								</div>
								)}
							>
								<p>
								789Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
								exercitation.</p>
							</TimelineItem>
							<TimelineItem
								key="004"
								dateText="08/2008 – 11/2008"
								dateInnerStyle={{ background: '#76bb7f' }}
							>
								<p>
								012Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
								exercitation.</p>
							</TimelineItem>
							
							</Timeline>
							<div className="row col-xl-12 col-lg-12 mb-30p">
								<button type="button" onClick={this.onCloseUploadDiv.bind(this)} className="btn btn-default cs-btn"> Cancel </button>
							</div>
				 		</div>
				 	</div>
				 </div>

				 
				<span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
				<Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
					<div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<h5 className="model-title">Consignees List</h5>
						<div className="col-xl-12 col-lg-12 mt-20p">
							<div className="col-xl-12 col-lg-12">
								This is the master which contains the details of consignee locations. This data consists of details like address,  geofence  co-ordinates, chettinad internal markings to identify the location 	
							</div>
							
							<div className="col-xl-12 col-lg-12">
								<ul>
									<li>
										<span style={{fontWeight:"bold"}}>1. For SND Dept:</span>
										<div className="col-xl-12 col-lg-12">
											<table className="table table-border">
												<tr>
													<th>Column</th>
													<th>
														Remarks
													</th>
												</tr>
												<tr>
													<td>
													Consignee Name
													</td>
													<td>
													Name of Consignee
													</td>
												</tr>
												<tr>
													<td>
													Consignee Code
													</td>
													<td>
													Code provided by chettinad
													</td>
												</tr>
												<tr>
													<td>
													Latitude
													</td>
													<td>
													GPS co-ordinates
													</td>
												</tr>
												<tr>
													<td>
													Longitude
													</td>
													<td>
													GPS co-ordinates
													</td>
												</tr>
												<tr>
													<td>
													For Code
													</td>
													<td>
													Code provided by chettinad
													</td>
												</tr>
												<tr>
													<td>
													Consignee City
													</td>
													<td>
													City of consignee point
													</td>
												</tr>
												<tr>
													<td>
													Address 1
													</td>
													<td>
													address of consignee 
													</td>
												</tr>
												<tr>
													<td>
													Address 2
													</td>
													<td>
													address of consignee 
													</td>
												</tr>
												<tr>
													<td>
													Address 3
													</td>
													<td>
													address of consignee 
													</td>
												</tr>
											</table>
										</div>
									</li>
									<li>
									<span style={{fontWeight:"bold"}}>2. For PRT Dept:</span>
										<div className="col-xl-12 col-lg-12">
											<table className="table table-border">
												<tr>
													<th>Column</th>
													<th>
														Remarks
													</th>
												</tr>
												<tr>
													<td>
													Consignee Name
													</td>
													<td>
													Name of Consignee
													</td>
												</tr>
												<tr>
													<td>
													Consignee Code
													</td>
													<td>
													Code provided by chettinad
													</td>
												</tr>
												<tr>
													<td>
													Latitude
													</td>
													<td>
													GPS co-ordinates
													</td>
												</tr>
												<tr>
													<td>
													Longitude
													</td>
													<td>
													GPS co-ordinates
													</td>
												</tr>
												
												<tr>
													<td>
													Address 
													</td>
													<td>
													Address of Destination
													</td>
												</tr>
												<tr>
													<td>
													Radius
													</td>
													<td>
													Geofence radius of destination
													</td>
												</tr>
												<tr>
													<td>
													Unloading Time
													</td>
													<td>
													Unloading time allocated by chettinad 
													</td>
												</tr>
											</table>
										</div>
									</li>
									<li>
										<span style={{fontWeight:"bold"}}>3. For TNP Dept:</span>
										<div className="col-xl-12 col-lg-12">
										This feature is not available for TNP department as of now
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</Modal>
            </div>
              	
		);
	}
}