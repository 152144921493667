import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const CombinedChart = ({ detentionRecords = [], title }) => {
    const chartRef = useRef(null); 

    // Function to generate gradient colors
    const getGradientColor = (color1, color2) => ({
        linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
        stops: [
            [0, color1],
            [1, color2],
        ],
    });

    
    const sourceWiseColors = [
        getGradientColor('#FF6347', '#FF4500'),  
        getGradientColor('#32CD32', '#228B22'),  
        getGradientColor('#8A2BE2', '#4B0082'), 
        getGradientColor('#FF1493', '#C71585'),  
        getGradientColor('#00BFFF', '#1E90FF'), 
        getGradientColor('#FFD700', '#FF8C00'), 
    ];

    const transporterWiseColors = [
        getGradientColor('#FF6B6B', '#FF8E53'),
        getGradientColor('#55EFC4', '#2ECC71'),
        getGradientColor('#4A90E2', '#3B5998'),
        getGradientColor('#FFC658', '#FFA726'),
        getGradientColor('#A29BFE', '#8E44AD'),
        getGradientColor('#FF6B81', '#FF4757'),
    ];

     // Function to generate a random solid color
     const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    // Function to return appropriate colors based on the title
    const getColors = (title) => {
        if (title === 'Source-wise Price') {
            return sourceWiseColors;
        } 
        // else if (title === 'Transporter-wise Price') {
        //     return transporterWiseColors;
        // }
         else {
            // Default case: generate random colors
            return Array.from({ length: detentionRecords.length }, () => getRandomColor());
        }
    };

    const colors = getColors(title);


    const data = detentionRecords.map((record, index) => ({
        name: record.name || 'Unknown',
        total: parseFloat(record.detentionPrice) || 0,
        color: colors[index % colors.length], // Loop through color set
    }));

    const categories = data.map(item => item.name);
    const totalValues = data.map(item => item.total);

    const options = {
        chart: {
            type: 'column',
            backgroundColor: null,
        },
        title: {
            text: '',
            align: 'left',
            style: {
                fontSize: '16px',
                fontWeight: 'bold',
            },
        },
        xAxis: {
            categories: categories,
            labels: {
                style: {
                    color: '#2C3E50', 
                    fontSize: '10px',
                    fontWeight: 'bold',
                },
                rotation: -45, // Rotate for better readability, as before
            },
            lineColor: '#ccc',
            gridLineWidth: 0,
        },
        yAxis: {
            title: {
                text: 'Price (Rs)',
            },
            gridLineWidth: 0,
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
        tooltip: {
            shared: true,
            pointFormat: '<b>Total Price:</b> {point.y} Rs<br/>',
        },
        plotOptions: {
            series: {
                borderRadius: 5,
            },
            column: {
                pointPadding: 0.3, // Thin bar width
                borderWidth: 0,
                borderRadius: 10, 
                colorByPoint: true,
            },
        },
        series: [
            {
                name: 'Total Price',
                data: data.map(item => ({
                    y: item.total, // Value for each bar
                    color: item.color, 
                })),
                colorByPoint: true, 
                tooltip: {
                    pointFormat: '<b>Total Price:</b> {point.y} Rs<br/>',
                },
            },
        ],
    };

    useEffect(() => {
        if (chartRef.current) {
            const chartInstance = chartRef.current.chart;
            chartInstance.reflow();
        }
    }, [detentionRecords]);

    return (
        <div style={{ width: '100%', height: '450px' }}>
            <h3 className="chart-title">{title}</h3>
            <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
        </div>
    );
};

export default CombinedChart;
