import React from 'react';
import { FaTruck, FaRupeeSign,FaChartLine } from 'react-icons/fa';
import './detentionDashboard.css';

const SummaryCard = ({ title, count,amount, backgroundColor }) => {
  return (
    <div className={`summary-card ${backgroundColor}`}>
      <div className="summary-card-content">
        {/* <FaChartLine size={50} className="summary-card-icon" /> */}
        <div className="summary-card-text p-0">
          <h3 className="summary-card-title">{title}</h3>
          <p className="summary-card-count">
            <FaTruck size={15} className="icon" /> {count}
          </p>
          <p className="summary-card-count mb-0 mt-0">
            <FaRupeeSign size={15} className="icon" /> {amount}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SummaryCard;
