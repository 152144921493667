import React, { useState, useEffect } from 'react';
import DieselPricesTable from './DieselPricesTable.js';
import DieselPricesCityWiseTable from './DieselPricesCityWiseTable.js';
import DieselPricesChart from './DieselPricesChart.js';
import 'react-datepicker/dist/react-datepicker.css';
import Datetime from 'react-datetime';
var redirectURL = require('../redirectURL');

const DieselDashboard = () => {
    const currentDate = new Date();
    const oneDayBefore = new Date();
    oneDayBefore.setDate(currentDate.getDate() - 7);
    const [filters, setFilters] = useState({
        baseDate: "",
        currentDate: oneDayBefore.toISOString().split('T')[0],
        endDate: currentDate.toISOString().split('T')[0]
    });
    const [loadshow, setLoadShow] = useState('show-m');
    // const [appliedFilters, setAppliedFilters] = useState(filters);
    const [showTableView, setShowTableView] = useState(true);
    const [dataLoaded, setDataLoaded] = useState(false)

    const handleSubmit = async () => {
        setLoadShow('show-m'); // Show loader and overlay
        await redirectURL.post("/consignments/getBasePriceDate", { "baseprice_date": filters.baseDate });
        // setAppliedFilters(filters); 
        // Apply the filters when Submit is clicked
    };

    const handleLoadingComplete = () => {
        setDataLoaded(true)
        setLoadShow('show-n');

        // Hide the loader and overlay once data loads // Hide the loader and overlay
    };

    const handleDateChange = (key, date) => {
        setFilters({
            ...filters,
            [key]: date.format("YYYY-MM-DD")
        });
    };

    const toggleView = () => {
        setLoadShow('show-m')
        setShowTableView(!showTableView);
    };

    useEffect(() => {
        const fetchBasePriceDate = async () => {
            try {
                setLoadShow('show-m')
                const response = await redirectURL.post("/consignments/getBasePriceDate", {});

                // Check for a valid response and handle unexpected formats
                if (response.data && response.data) {
                    console.log(response.data, "check_response")
                    var res = response.data.date
                    const isoDate = new Date(res.baseprice_date);
                    const formattedDate = isoDate.toISOString().split('T')[0];
                    console.log(formattedDate, "formattedDate")
                    setFilters(prevFilters => ({
                        ...prevFilters,
                        baseDate: formattedDate
                    }));
                } else {
                    console.error("Expected date data but received:", response.data);
                }
            } catch (error) {
                console.error("Error fetching data", error);
            } finally {
                // setLoadShow('show-n')
            }
        };

        fetchBasePriceDate();
    }, []);

    // Use useEffect to log `filters` after it's updated
    // useEffect(() => {
    //     console.log(filters, "filters updated");
    // }, [filters]);

    return (
        <div className="container-fluid card" style={{ padding: '20px' }}>
            <h5 className="dashboard-title mb-3">Diesel Price Report</h5>
            <div className="d-flex flex-row card mb-2 ml-0p">
                <div className="form-group" style={filterGroupStyle}>
                    <label>Base Date</label>
                    <Datetime
                        value={filters.baseDate}
                        disableCloseOnClickOutside={false}
                        closeOnSelect={true}
                        inputProps={{ placeholder: 'Select Date', name: 'baseDate', autoComplete: 'off'}}
                        dateFormat="YYYY-MM-DD"
                        timeFormat={false}
                        onChange={(date) => handleDateChange("baseDate", date)}
                    />
                </div>
                <div className="form-group" style={filterGroupStyle}>
                    <label>Start Date</label>
                    <Datetime
                        value={filters.currentDate}
                        disableCloseOnClickOutside={false}
                        closeOnSelect={true}
                        inputProps={{ placeholder: 'Select Date', name: 'startDate', autoComplete: 'off'}}
                        dateFormat="YYYY-MM-DD"
                        timeFormat={false}
                        onChange={(date) => handleDateChange("currentDate", date)}
                    />
                </div>
                <div className="form-group" style={filterGroupStyle}>
                    <label>End Date</label>
                    <Datetime
                        value={filters.endDate}
                        disableCloseOnClickOutside={false}
                        closeOnSelect={true}
                        inputProps={{ placeholder: 'Select Date', name: 'endDate', autoComplete: 'off'}}
                        dateFormat="YYYY-MM-DD"
                        timeFormat={false}
                        onChange={(date) => handleDateChange("endDate", date)}
                    />
                </div>
                <div className="form-group mt-25p" style={{ marginLeft: "0px" }}>
                    <button onClick={handleSubmit} style={submitButtonStyle}>Submit</button>
                </div>

                <div className="ml-auto align-self-center">
                    <p style={{
                        color: "red",
                        textAlign: "right",
                        marginBottom: 0,
                        marginRight: "2px",
                        fontSize: "14px",
                        marginTop : "56px"
                    }}>
                        * Diesel prices will be updated daily by 10 AM.
                    </p>
                </div>

                
            </div>
            
            {loadshow === "show-n" && dataLoaded ? (
                <div className="d-flex justify-content-end" style={{ marginTop: '10px', alignItems: 'flex-end' }}>
                    <button onClick={toggleView} style={toggleButtonStyle}>
                        {showTableView ? "View Chart" : "View Data"}
                    </button>
                </div>
            ) : null}

            {/* Conditional Rendering for Tables or Chart */}
            {console.log(showTableView, "showTableView")}
            {showTableView ? (
                <div>
                    <div className='d-flex justify-content-around'>
                        {/* {console.log(appliedFilters.baseDate, "check_basedate")} */}
                        <DieselPricesTable
                            baseDate={filters.baseDate}
                            // currentDate={appliedFilters.currentDate} 
                            currentDate={filters.endDate}
                            title="Outbound"
                            onLoadingComplete={handleLoadingComplete}
                        />
                        <DieselPricesTable
                            baseDate={filters.baseDate}
                            currentDate={filters.endDate}
                            // currentDate={appliedFilters.currentDate} 
                            title="Inbound"
                            onLoadingComplete={handleLoadingComplete}
                        />
                    </div>
                    <div className='mb-5'>
                        <DieselPricesCityWiseTable
                            baseDate={filters.baseDate}
                            currentDate={filters.currentDate}
                            endDate={filters.endDate}
                            title="Diesel Prices Summary"
                            onLoadingComplete={handleLoadingComplete}
                        />
                    </div>
                </div>) : (
                <DieselPricesChart
                    baseDate={filters.baseDate}
                    // currentDate={appliedFilters.currentDate}
                    currentDate={filters.endDate}
                    title="Diesel Prices"
                    onLoadingComplete={handleLoadingComplete}
                />)}
            {/* Loader and overlay */}

            <div className={"dataLoadpage " + loadshow}></div>
            <div className={"dataLoadpageimg " + loadshow}>
                <div className="loader-box">
                    <div className="rotate dashed colored"></div>
                </div>
            </div>

        </div>
    );
};

const filterGroupStyle = {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '150px',
    margin: '0 10px',
};

const submitButtonStyle = {
    padding: '10px 20px',
    borderRadius: '4px',
    backgroundColor: '#4CAF50',
    color: '#fff',
    fontWeight: 'bold',
    border: 'none',
    cursor: 'pointer',
};

const toggleButtonStyle = {
    padding: '8px 16px',
    borderRadius: '4px',
    backgroundColor: '#00c292',
    color: '#fff',
    fontWeight: 'bold',
    border: 'none',
    cursor: 'pointer',
};

export default DieselDashboard;
