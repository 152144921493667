import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import Datetime from 'react-datetime';
var redirectURL = require('../redirectURL');

const FilterComponent = ({ onSubmit,isAdvancedFilter,onResetAdvancedFilter   }) => {
    const currentDate = new Date();
    const oneDayBefore = new Date(); //By default kept current date for from date and to date
    // oneDayBefore.setDate(currentDate.getDate() - 7); //7 days data from current date and time
    const [year, setYear] = useState(null);
    const [quarter, setQuarter] = useState(null);
    const [month, setMonth] = useState([]);
    const [fromDate, setFromDate] = useState(oneDayBefore);
    const [toDate, setToDate] = useState(currentDate);
    const [transporter, setTransporter] = useState('');
    const [consigneeCode, setConsigneeCode] = useState('');
    const [sourceCode, setSourceCode] = useState('');
    const [sourceCodeOptions, setSourceCodeOptions] = useState([]);
    const [tripStatus, setTripStatus] = useState({
        "label": "Waiting For Unload", "value": 1
    });
    const [tracking, setTracking] = useState('')
    const [consigneeCity, setConsigneeCity] = useState('');
    const [consigneeState, setConsigneeState] = useState('');
    const [transporterOptions, setTransporterOptions] = useState([]);
    const [consigneeCodeOptions, setConsigneeCodeOptions] = useState([]);
    const [tripStatusOptions, setTripStatusOptions] = useState([{
        "label": "Waiting For Unload", "value": 1
    }, { "label": "Trip Closure", "value": 2 }]);
    const [trackingOptions, setTrackingOptions] = useState([{
        "label": "GPS", "value": "GPS"
    }, { "label": "SIM", "value": "SIM" }]);
    const [consigneeCityOptions, setConsigneeCityOptions] = useState([]);
    const [consigneeStateOptions, setConsigneeStateOptions] = useState([]);

    const generateYearOptions = (currentYear, startYear = 2023) => {
        const endYear = currentYear; // Use current year as the end year
        return Array.from({ length: endYear - startYear + 1 }, (_, index) => {
            const year = startYear + index;
            return { value: year, label: year };
        });
    };
    
    // Example usage:
    const currentYear = new Date().getFullYear(); // Replace with your desired year
    const yearOptions = generateYearOptions(currentYear);
    
    const quarterOptions = [
        { value: "Q1", label: "Q1 (Jan-Mar)" },
        { value: "Q2", label: "Q2 (Apr-Jun)" },
        { value: "Q3", label: "Q3 (Jul-Sep)" },
        { value: "Q4", label: "Q4 (Oct-Dec)" },
    ];
    const allMonthOptions = [
        { value: 0, label: "January" },
        { value: 1, label: "February" },
        { value: 2, label: "March" },
        { value: 3, label: "April" },
        { value: 4, label: "May" },
        { value: 5, label: "June" },
        { value: 6, label: "July" },
        { value: 7, label: "August" },
        { value: 8, label: "September" },
        { value: 9, label: "October" },
        { value: 10, label: "November" },
        { value: 11, label: "December" },
    ];
    const [monthOptions, setMonthOptions] = useState(allMonthOptions);
    const quarterToMonths = {
        Q1: [0, 1, 2], // Jan - Mar
        Q2: [3, 4, 5], // Apr - Jun
        Q3: [6, 7, 8], // Jul - Sep
        Q4: [9, 10, 11], // Oct - Dec
    };

    const handleSubmit = () => {
        if (!year && isAdvancedFilter) {
            alert("Year is required.");
            return false;
        }

        // if (year && !quarter && (Array.isArray(month) && month.length === 0) && isAdvancedFilter) {
        //     alert("Quarter or Month is required when a year is selected.");
        //     return false;
        // }
        onSubmit({
            fromDate: fromDate ? fromDate.toISOString().split('T')[0] : null,
            toDate: toDate ? toDate.toISOString().split('T')[0] : null,
            transporter,
            consigneeCode,
            sourceCode,
            consigneeCity,
            consigneeState,
            tripStatus,
            tracking
        });
    };

    const handleReset = () => {
        const defaultFromDate = currentDate;
        const defaultToDate = currentDate;
        const defaultTripStatus = { label: "Waiting for Unload", value: 1 };

        // Reset state values
        setTransporter('');
        setConsigneeCode('');
        setSourceCode('');
        setConsigneeCity('');
        setConsigneeState('');
        setFromDate(defaultFromDate);
        setToDate(defaultToDate);
        setTripStatus(defaultTripStatus);
        setTracking('');
        setYear(null);
        setQuarter(null);
        setMonth([]);

        onResetAdvancedFilter();  //this resets isAdvancedFilter prop

        onSubmit({
            fromDate: defaultFromDate.toISOString().split('T')[0],
            toDate: defaultToDate.toISOString().split('T')[0],
            transporter: '',
            consigneeCode: '',
            sourceCode: '',
            consigneeCity: '',
            consigneeState: '',
            tripStatus: defaultTripStatus,
            tracking:'',
            year: null,
            quarter: null,
            month: [],
        });
    };

    const handleYearChange = (selectedOption) => {
        setYear(selectedOption.value);
        const yearValue = selectedOption.value;
        const startDate = new Date(Date.UTC(yearValue, 0, 1));
        const endDate = new Date(Date.UTC(yearValue, 11, 31));
        setFromDate(startDate);
        setToDate(endDate);
    };

    const handleQuarterChange = (selectedQuarter) => {
        if (selectedQuarter) {
            setQuarter(selectedQuarter.value);

            // Get the start and end dates for the selected quarter
            const quarterMapping = {
                Q1: { start: new Date(Date.UTC(year, 0, 1)), end: new Date(Date.UTC(year, 2, 31)) },
                Q2: { start: new Date(Date.UTC(year, 3, 1)), end: new Date(Date.UTC(year, 5, 30)) },
                Q3: { start: new Date(Date.UTC(year, 6, 1)), end: new Date(Date.UTC(year, 8, 30)) },
                Q4: { start: new Date(Date.UTC(year, 9, 1)), end: new Date(Date.UTC(year, 11, 31)) },
            };
            const { start, end } = quarterMapping[selectedQuarter.value];
            setFromDate(start); // Format as YYYY-MM-DD
            setToDate(end);
            setMonth([]);
            // Filter months dynamically based on the selected quarter
            const monthsForQuarter = quarterToMonths[selectedQuarter.value];
            const filteredMonthOptions = allMonthOptions.filter((month) =>
                monthsForQuarter.includes(month.value)
            );
            setMonthOptions(filteredMonthOptions);
        }
    };

    const handleMonthChange = (selectedOptions) => {
        if (selectedOptions && selectedOptions.length > 0) {
            setMonth(selectedOptions);
            // setQuarter(null); 
            let selectedMonth = parseInt(selectedOptions[0].value);
            const startDates = selectedOptions.map((option) => new Date(Date.UTC(year, selectedMonth, 1))); // First day of each month
            const endDates = selectedOptions.map((option) => new Date(Date.UTC(year, selectedMonth + 1, 0))); // Last day of each month

            const earliestStart = new Date(Math.min(...startDates));
            const latestEnd = new Date(Math.max(...endDates));

            setFromDate(earliestStart);
            setToDate(latestEnd);
            // setQuarter(null)
        }
    };

    

    const handleFromDateChange = (date) => {

        if (toDate && date.isAfter(toDate)) {
            alert("From Date should be less than or equal to To Date");
        } else {
            setFromDate(date);
        }
    };

    const handleToDateChange = (date) => {

        if (fromDate && date.isBefore(fromDate)) {
            alert("To Date should be greater than or equal to From Date");
        } else {
            setToDate(date);
        }
    };

    useEffect(() => {
        try {
            redirectURL.post('carlsberg/fetchFilterData')
                .then((response) => {
                    const records = response.data;
                    // console.log(records, "recordsss")
                    const trimmedStates = records.consigneStateInfo
                        .filter(state => state !== "\"" && state.trim() !== "")
                        .map(state => state.startsWith('"') ? state.slice(1).trim() : state.trim());

                    const uniqueTrimmedStates = [...new Set(trimmedStates)];

                    const trimmedCities = records.consigneCityInfo
                        .map(city => city.trim())
                        .filter(city => city !== "");

                    const uniqueTrimmedCities = [...new Set(trimmedCities)]; 

                    setTransporterOptions(records.transporter_info.map(option => ({
                        label: `${option.transporter_code} - ${option.transporter_name}`,
                        value: option.transporter_code
                    })));

                    setConsigneeCodeOptions(records.customer_info.map(option => ({
                        label: `${option.consignee_code} - ${option.consignee_name}`,
                        value: option.consignee_code
                    })));
                    if (["", undefined, null].includes(localStorage.getItem("pc"))) {
                        const mappedOptions = records.source_info.map(option => ({
                            label: `${option.plant_code} - ${option.plant_name}`,
                            value: option.plant_code
                        }));
                        setSourceCodeOptions(mappedOptions);
                    } else {
                        const pc = localStorage.getItem("pc");
                        const filteredOptions = records.source_info.filter(option => option.plant_code === pc);

                        if (filteredOptions.length > 0) {
                            const mappedOptions = filteredOptions.map(option => ({
                                label: `${option.plant_code} - ${option.plant_name}`,
                                value: option.plant_code
                            }));
                            setSourceCodeOptions(mappedOptions);
                            setSourceCode(mappedOptions.length > 0 ? mappedOptions[0].value : ""); // Set the default value
                        } else {
                            const mappedOptions = records.source_info.map(option => ({
                                label: `${option.plant_code} - ${option.plant_name}`,
                                value: option.plant_code
                            }));
                            setSourceCodeOptions(mappedOptions);
                        }
                    }                    

                    setConsigneeCityOptions(uniqueTrimmedCities.map(city => ({
                        label: city,
                        value: city
                    })));

                    setConsigneeStateOptions(uniqueTrimmedStates.map(state => ({
                        label: state,
                        value: state
                    })));


                });
        }
        catch (e) {
            console.log("error", e)
        }

    }, [])

    return (
        <div className="container-fluid card p-2">
            <div className="col-xl-12 col-lg-12 d-flex mb-2 ml-0p" style={isAdvancedFilter ? { flexWrap: 'wrap' } : undefined} >

                {isAdvancedFilter ? (
                    <>
                        {/* Year Dropdown */}
                        <div className="form-group" style={filterGroupStyle}>
                            <label>Year</label>
                            <Select
                                className="border-radius-0"
                                isMulti={false}
                                closeMenuOnSelect={true}
                                // value={yearOptions.find((y) => y.value === year)}
                                value={year ? yearOptions.find((y) => y.value === year) : null}
                                onChange={handleYearChange}
                                options={yearOptions}
                                placeholder="Select"
                            />
                        </div>

                        {/* Quarter Dropdown */}
                        {/* {year && ( */}
                        <div className="form-group" style={filterGroupStyle}>
                            <label>Quarter</label>
                            <Select
                                className="border-radius-0"
                                isMulti={false}
                                closeMenuOnSelect={true}
                                // value={quarterOptions.find((q) => q.value === quarter)}
                                value={quarter ? quarterOptions.find((q) => q.value === quarter) : null}
                                onChange={handleQuarterChange}
                                options={quarterOptions}
                                placeholder="Select"
                            />
                        </div>
                        {/* )} */}

                        {/* Month Dropdown */}
                        {/* {year && ( */}
                        <div className="form-group" style={filterGroupStyle}>
                            <label>Month</label>
                            <Select
                                className="border-radius-0"
                                isMulti={true}
                                closeMenuOnSelect={true}
                                // value={monthOptions.find((m) => m.value === month)}
                                // value={month.length ? monthOptions.find((m) => m.value === month) : []}
                                value={month}
                                onChange={handleMonthChange}
                                options={monthOptions}
                                placeholder="Select"
                            />
                        </div>
                        {/* )} */}
                    </>
                ) : (<>
                    <div className="form-group" style={filterGroupStyle}>
                        <label>From Date</label>

                        <Datetime value={fromDate}
                            disableCloseOnClickOutside={false}
                            closeOnSelect={true}
                            inputProps={{ placeholder: 'Select Date', name: 'startDate', autoComplete: 'off'}}
                            dateFormat="YYYY-MM-DD"
                            timeFormat={false}
                            name="fromDate"
                            onChange={handleFromDateChange} />
                    </div>
                    <div className="form-group" style={filterGroupStyle}>
                        <label>To Date</label>

                        <Datetime
                            value={toDate}
                            disableCloseOnClickOutside={false}
                            closeOnSelect={true}
                            inputProps={{ placeholder: 'Select Date', name: 'toDate', autoComplete: 'off'}}
                            dateFormat="YYYY-MM-DD"
                            timeFormat={false}
                            name="toDate"
                            onChange={handleToDateChange} />
                    </div>
                </>)}

                <div className="form-group" style={filterGroupStyle}>
                    <label>Status</label>
                    <Select
                        className="border-radius-0"
                        isMulti={false}
                        closeMenuOnSelect={true}
                        value={tripStatus}
                        onChange={setTripStatus}
                        options={tripStatusOptions}
                        placeholder="Select"
                        styles={{
                            placeholder: (provided) => ({
                                ...provided,
                                fontSize: '14px',
                            }),
                        }}
                    />
                </div>

                <div className="form-group" style={filterGroupStyle}>
                    <label>Transporter</label>
                    <Select
                        className="border-radius-0"
                        isMulti={false}
                        closeMenuOnSelect={true}
                        value={transporter}
                        onChange={setTransporter}
                        options={transporterOptions}
                        placeholder="Select"
                        styles={{
                            placeholder: (provided) => ({
                                ...provided,
                                fontSize: '14px',
                            }),
                        }}
                    />

                </div>
                <div className="form-group" style={filterGroupStyle}>
                    <label>Source</label>
                    <Select
                        className="border-radius-0"
                        isMulti={false}
                        closeMenuOnSelect={true}
                        value={
                            sourceCodeOptions.find(option => option.value === sourceCode) || null
                        }
                        onChange={(selectedOption) => setSourceCode(selectedOption ? selectedOption.value : "")}
                        options={sourceCodeOptions}
                        placeholder="Select"
                        styles={{
                            placeholder: (provided) => ({
                                ...provided,
                                fontSize: '14px',
                            }),
                        }}
                    />
                </div>
                {/* <div className="form-group" style={filterGroupStyle}>
                    <label>Tracking Type</label>
                    <Select
                        className="border-radius-0"
                        isMulti={false}
                        closeMenuOnSelect={true}
                        value={tracking}
                        onChange={setTracking}
                        options={trackingOptions}
                        placeholder="Select"
                        styles={{
                            placeholder: (provided) => ({
                                ...provided,
                                fontSize: '14px',
                            }),
                        }}
                    />
                </div> */}
                {/* <div className="form-group" style={filterGroupStyle}>
                    <label>Dealer</label>
                    <Select
                        className="border-radius-0"
                        isMulti={false}
                        closeMenuOnSelect={true}
                        value={consigneeCode}
                        onChange={setConsigneeCode}
                        options={consigneeCodeOptions}
                        placeholder="Select"
                        styles={{
                            placeholder: (provided) => ({
                                ...provided,
                                fontSize: '12px',
                            }),
                        }}
                    />
                </div>
                <div className="form-group" style={filterGroupStyle}>
                    <label>Dealer City</label>
                    <Select
                        className="border-radius-0"
                        isMulti={false}
                        closeMenuOnSelect={true}
                        value={consigneeCity}
                        onChange={setConsigneeCity}
                        options={consigneeCityOptions}
                        placeholder="Select"
                        styles={{
                            placeholder: (provided) => ({
                                ...provided,
                                fontSize: '12px',
                            }),
                        }}
                    />
                </div> */}
                {/* <div className="form-group" style={filterGroupStyle}>
                    <label>Consignee State</label>
                    <Select
                        className="border-radius-0"
                        isMulti={false}
                        closeMenuOnSelect={true}
                        value={consigneeState}
                        onChange={setConsigneeState}
                        options={consigneeStateOptions}
                        placeholder="Select"
                        styles={{
                            placeholder: (provided) => ({
                                ...provided,
                                fontSize: '12px',
                            }),
                        }}
                    />
                </div> */}
                
                    {/* <div className="form-group mt-25p" style={{ marginRight: "0px" }}>
                        <button onClick={handleSubmit} style={submitButtonStyle}>Submit</button>
                    </div>
                    <div className="form-group mt-25p" style={{ marginLeft: "0px" }}>
                        <button onClick={handleReset} style={resetButtonStyle}>Reset</button>
                    </div> */}
               
                <div className={isAdvancedFilter?"d-flex justify-content-end mt-27p":"d-flex mt-27p"}>
                    <div className="form-group" style={{ marginLeft: "0px" }}>
                        <button onClick={handleSubmit} style={submitButtonStyle}>Submit</button>
                    </div>
                    <div className="form-group" style={{ marginLeft: "0px" }}>
                        <button onClick={handleReset} style={resetButtonStyle}>Reset</button>
                    </div>
                </div>

            </div>
        </div>

    );
};

// Styles for the filter component
const filterContainerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    padding: '20px',
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
};

const filterGroupStyle = {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '150px',
    // maxWidth : '180px',
    margin: '0 10px',
};

const selectStyle = {
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #ccc',
};

const submitButtonStyle = {
    padding: '10px 20px',
    borderRadius: '4px',
    backgroundColor: '#4CAF50',
    color: '#fff',
    fontWeight: 'bold',
    border: 'none',
    cursor: 'pointer',
};

const resetButtonStyle = {
    padding: '10px 20px',
    borderRadius: '4px',
    backgroundColor: '#d9534f', // Red color
    color: '#fff',
    fontWeight: 'bold',
    border: 'none',
    cursor: 'pointer',
    marginLeft: '10px',
};

export default FilterComponent;
