import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';

const COLORS = ['#8884d8', '#82ca9d', '#ffc658', '#FF6B6B', '#FFB6C1', '#FF69B4', '#BA55D3', '#9370DB'];

NoDataToDisplay(Highcharts);

const HighchartsDonut = ({ detentionRecords = [], title }) => {
    const [chartOptions, setChartOptions] = useState({});
    const [total, setTotal] = useState(0);

    useEffect(() => {
        const transformedData = (detentionRecords && detentionRecords.length > 0) ? detentionRecords.map((item, index) => ({
            name: item.name,
            y: parseFloat(item.averageDetentionDays),
            color: COLORS[index % COLORS.length],
        })) : []


        const calculatedTotal = transformedData.reduce((sum, entry) => sum + entry.y, 0);
        setTotal(calculatedTotal);

        setChartOptions({
            chart: {
                type: 'pie',
                backgroundColor: '#f9f9f9',
                spacingBottom: 20,
                spacingTop: 20,
                spacingLeft: 20,
                spacingRight: 20,
            },
            title: {
                text: '',
            },
            plotOptions: {
                pie: {
                    innerSize: '60%', // Creates a donut effect
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}: {point.y:.1f} days',
                        style: {
                            fontSize: '10px',
                            fontWeight: 'bold',
                            color: '#333',
                        },
                    },
                },
            },
            tooltip: {

                pointFormat: '{point.name}: <b>{point.y:.1f} days</b> ({point.percentage:.1f}days)',
                backgroundColor: '#fff',
                borderColor: '#ddd',
                borderRadius: 8,
                style: {
                    fontSize: '12px',
                    padding: '10px',
                },
            },
            series: [
                {
                    name: 'Detention Hours',
                    data: transformedData,
                    size: '80%',
                    showInLegend: false,
                },
            ],
            legend: {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
        });
    }, [detentionRecords]);

    return (
        <div className='d-flex justify-content-center align-items-center flex-column'>
            <h3 className="chart-title">{title}</h3>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
    );
};

export default HighchartsDonut;
