import React from 'react';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';


HighchartsMore(Highcharts);

const StatewisePebbleChart = ({ detentionRecords, title }) => {
    const data = detentionRecords.map(record => ({
        name: record.name,
        value: parseFloat(record.averageDetentionDays),
    }));

    const options = {
        chart: {
            type: 'packedbubble',
            height: '70%',
            backgroundColor: null
        },
        title: {
            text: '',
        },
        tooltip: {
            useHTML: true,
            pointFormat: '<b>{point.name}:</b> {point.value} days',
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        plotOptions: {
            packedbubble: {
                minSize: '50%',
                maxSize: '200%',
                zMin: 0,
                zMax: 100,
                layoutAlgorithm: {
                    gravitationalConstant: 0.02,
                    splitSeries: false,
                    seriesInteraction: false,
                    dragBetweenSeries: true,
                    parentNodeLimit: true,
                },
                dataLabels: {
                    enabled: true,
                    format: '{point.name}<br>{point.value} days',
                    style: {
                        color: '#3F51B5',
                        textOutline: 'none',
                        fontWeight: 'bold',
                    },
                },
            },
            series: {
                colorByPoint: true,
                colors: [
                    '#4CAF50', // Green
                    '#2196F3', // Blue
                    '#FFC107', // Yellow (kept vibrant yellow)
                    '#FF5722', // Deep Orange
                    '#9C27B0', // Purple
                    '#00BCD4', // Cyan
                    '#FF9800', // Orange
                    '#E91E63', // Pink
                    '#3F51B5'  // Indigo
                ],
            },
        },
        series: [
            {
                name: 'States',
                data: detentionRecords.map(record => ({
                    name: record.name,
                    value: parseFloat(record.averageDetentionDays),
                })),
            },
        ],
    };



    return (
        <div className='d-flex justify-content-center align-items-center flex-column'>
            <h3 className="chart-title">{title}</h3>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};

export default StatewisePebbleChart;
